import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'

export default () => (
  <Layout>
    <Helmet>
      <title>FAQ’s - Plastic Surgery Sydney</title>
      <meta name="description" content="" />
    </Helmet>
    <Header>
      <h1>Frequently asked questions</h1>
    </Header>
    <FAQs />
    <CTA
      title="Having an issue?"
      subtitle="Rob's here to help you."
      button="Contact Rob Today"
    />
  </Layout>
)
