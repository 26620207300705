import * as React from 'react'
import styled, { css } from 'styled-components'
import { xPad, yPad, yMargin } from '../utils/theme'
import Accordion from './Accordion'
import { darken } from 'polished'

interface IFAQContainerProps {
  flushTop: boolean
}

const FAQContainer = styled<IFAQContainerProps, any>('div')`
  background-color: rgb(241, 248, 254);
  ${yMargin};
  margin-bottom: 0 !important;

  ${props =>
    props.flushTop &&
    css`
      margin-top: 0 !important;
    `};
`

const FAQs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin: 0 auto;
  max-width: 1000px;

  ${yPad};
  ${xPad};

  h3 {
    color: ${props => darken(0.4, props.theme.colors.primary)};
    &:not(:first-child) {
      padding-top: 2em;
    }
  }
`

interface IFAQ {
  title?: string
  answer?: string
  question?: string
  isHtml?: boolea
}

interface IFAQProps {
  flushTop?: boolean
  title: string
  data: IFAQ[]
}

const data = [
  {
    question: 'Do you work with children?',
    answer: 'test'
  },

  {
    question: 'Do you work with adolescents?',
    answer: 'test'
  },

  {
    question: 'How do I set up an appointment',
    answer: 'test'
  },

  {
    question: 'Do you see NDIS, WorkCover and ComCare clients?',
    answer: 'test'
  },

  {
    question: 'Can I have a home based appointment?',
    answer: 'test'
  },

  {
    question: 'How long does a typical session last?',
    answer: 'test'
  },

  {
    question: 'Are you clinical psychologists?',
    answer: 'test'
  },

  {
    question: 'Do you offer psychological assessments?',
    answer: 'test'
  }
]

export default ({ title, faqs, ...rest }: IFAQProps) => {
  const info: IFAQ[] = faqs || data
  return (
    <FAQContainer {...rest}>
      <FAQs>
        <h3>{title}</h3>
        {info.map(faq => {
          if (faq.answer && faq.question) {
            return (
              <Accordion
                answer={faq.answer}
                key={faq.question}
                question={faq.question}
                isHtml={faq.isHtml}
              />
            )
          }
        })}
      </FAQs>
    </FAQContainer>
  )
}
