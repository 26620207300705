import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'
import FAQs from '../../components/FAQs'
import faqs from '../faqs'

const content = {
  backgroundTitle: 'Other Psychology Services',
  subtitle: `Psychological assessments and tests.`,
  title: 'Assessments',
  otherOptions: [
    {
      text: 'Disability Specialist Services',
      to: '/services/positive-behaviour-support'
    },
    {
      text: 'Supervision',
      to: '/services/supervision'
    },
    {
      text: 'Mental Health Services',
      to: '/services/mental-health-services'
    }
  ]
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>Assessments - Brisbane & Gold Coast Psychology - Upright Performance</title>
      <meta name="description" content="" />
    </Helmet>

    <Hero skew={true} bgImage={data.assessments.childImageSharp} height="600px">
      <h1>Upright Performance &mdash; {content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero>

    <Content>
      <h2>Assessments</h2>

      <h4>Areas of Assessment</h4>

      <p>Upright Performance can provide assessments in the following areas:</p>

      <ul>
        <li>
          Intellectual (IQ) Assessments for the purpose of assisting with
          diagnosis and informing carers of potential need for supports
        </li>
        <li>Adaptive Functioning Assessment</li>
        <li>Mental Health screening assessments</li>
        <li>
          Assessment to assist in determining NDIS eligibility and
          recommendations for individual level/s of support
        </li>
      </ul>

      <p>
        Get in touch with us today to find out more about the different
        assessments, diagnoses and screenings we can offer.
      </p>

      <Link to="/contact/">
        <Button inverted={true}>Contact Rob Today</Button>
      </Link>
    </Content>

    {/* <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="400px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        View Upright Performance's other Psychology services.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection> */}

    <FAQs
      data={faqs}
      flushTop={true}
      title="Common Psychology Assessment Questions"
    />

    <AboutContact noBorder={true} data={data} />
  </MainLayout>
)
export const query = graphql`
  query AssessmentQuery {
    assessments: file(relativePath: { eq: "assessments.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "happy-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    drSellars: file(relativePath: { eq: "camping.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
